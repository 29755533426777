import { useCallback, useMemo, useState } from 'react';
import SignUpModal from 'components/SignupModal';
import { DropdownMenuTextOption } from '@alltrails/shared/types/dropdownMenu';
import useUser from 'hooks/useUser';
import useLanguageRegionCode from '@alltrails/shared/hooks/useLanguageRegionCode';
import useCountryId from 'hooks/useCountryId';
import { UNITED_STATES_ID } from 'constants/CountryIDs';
import { AuthRequiredHeaderLink, BasicHeaderLink, HeaderLink, getHeaderLinks } from './headerLinks';

export type GetLinkInteractivity = (link: HeaderLink) => {
  linkInfo?: DropdownMenuTextOption['linkInfo'];
  onClick?: () => void;
};

const useHeaderLinks = () => {
  const user = useUser();
  const languageRegionCode = useLanguageRegionCode();
  const inUS = (useCountryId() ?? UNITED_STATES_ID) === UNITED_STATES_ID;
  const [authRequiredLink, setAuthRequiredLink] = useState<AuthRequiredHeaderLink>();

  const headerLinks = useMemo(() => getHeaderLinks(user, languageRegionCode, inUS), [inUS, languageRegionCode, user]);

  const getLinkInteractivity: GetLinkInteractivity = useCallback(
    link => {
      let linkInfo: DropdownMenuTextOption['linkInfo'];
      let { onClick } = link;

      if (link.requiresAuth) {
        if (user) {
          linkInfo = link.getLinkInfo(user);
        } else {
          onClick = () => {
            link.onClick?.();
            setAuthRequiredLink(link);
          };
        }
      } else {
        linkInfo = (link as unknown as BasicHeaderLink).linkInfo;
      }

      return { linkInfo, onClick };
    },
    [user]
  );

  const signUpModal = useMemo(
    () => (
      <SignUpModal
        onRequestClose={() => setAuthRequiredLink(undefined)}
        isOpen={Boolean(authRequiredLink)}
        onSuccess={loggedInUser => {
          if (authRequiredLink) {
            window.location.assign(authRequiredLink.getLinkInfo(loggedInUser).href);
          }
        }}
      />
    ),
    [authRequiredLink]
  );

  return useMemo(() => ({ headerLinks, getLinkInteractivity, signUpModal }), [headerLinks, getLinkInteractivity, signUpModal]);
};

export default useHeaderLinks;
